import type { FC, ReactNode } from 'react';

export type Nullable<T> = T | null | undefined;

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

declare global {
  interface Window {
    googletag: unknown;
    // @ts-expect-error These defined values don't match the interface MediaQueryList
    matchMedia: (query: string) => {
      writable: boolean;
      value: {
        matches: boolean;
        media: typeof query;
        onchange: null;
        addListener: () => void;
        removeListener: () => void;
        addEventListener: () => void;
        removeEventListener: () => void;
        dispatchEvent: () => void;
      };
    };
  }
}

export enum LanguageCode {
  English = 'en',
  Swedish = 'sv',
  French = 'fr',
  German = 'de',
}

type Children = {
  children: ReactNode;
};

export type FWC = FC<Children>;
