/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'react-responsive-modal/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { IntlProvider } from 'gatsby-plugin-react-intl';
import { ToastContainer } from 'react-toastify';

import { LanguageCode } from './src/lib/types';

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <IntlProvider locale={LanguageCode.English}>{element}</IntlProvider>
      <ToastContainer />
    </>
  );
};
